body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

p {
	margin: 0 !important;
	padding: 0 !important;
}
:root {
	--grey-900: #102a43;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.active-link {
	background: #e2f0ff;
	border-right: 3px solid var(--grey-900);
	color: var(--grey-900);
}

.active-link span {
	font-weight: bold;
}

.item-link:hover {
	background: #e2f0ff;
	color: black;
}

@keyframes spinner {
	to {
		transform: rotate(360deg);
	}
}

.fall-back {
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loading {
	width: 6rem;
	height: 6rem;
	border: 5px solid #9fb3c8;
	border-radius: 50%;
	border-top-color: var(--grey-900);
	animation: spinner 2s linear infinite;
}

.small-loader {
	width: 20px;
	height: 20px;
	border: 2px solid #9fb3c8;
	border-radius: 50%;
	border-top-color: var(--grey-900);
	animation: spinner 0.5s linear infinite;
}

.center-loader {
	display: flex;
	justify-content: center;
}

.richText img {
	width: 100%;
}
